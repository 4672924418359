<template>
  <div>
    <activity-report role="admin"></activity-report>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import store from '@/store/store'
import ActivityReport from '@/modules/core/components/ActivityReport'

export default {
  name: 'UserActivity',
  components: {
    ActivityReport
  },
  beforeCreate() {
    let hasPermission = store.getters['Ability/manageAdminBoard']
    if (!hasPermission) {
      this.$toasted.show('Page access restricted. Please contact admin.', {
        icon: 'chain-broken',
        type: 'error'
      })
      this.$router.push('/dashboard')
    }
  },
  computed: {
    ...mapGetters('Ability', ['manageAdminBoard'])
  }
}
</script>
